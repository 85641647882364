import { render, staticRenderFns } from "./ListDiscussion.vue?vue&type=template&id=09c1f334"
import script from "./ListDiscussion.vue?vue&type=script&lang=js"
export * from "./ListDiscussion.vue?vue&type=script&lang=js"
import style0 from "./ListDiscussion.vue?vue&type=style&index=0&id=09c1f334&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports