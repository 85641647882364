<template>
  <div>

    <v-row>
      <v-col>
        <v-card class="elevation-0">
          <v-card-text class="font-weight-medium">
            <v-row>
              <v-col cols="12" md="2">
                <v-select :items="reportTypeList" readonly v-model="reportType" label="Jenis Laporan">
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field append-icon="$search" class="pt-0 mt-0 mr-4" label="Cari nama file import"
                  v-model="searching" @keydown.enter="fetch" outlined>
                  <template v-slot:append v-if="searching">
                    <v-icon @click="searching = ''; fetch()">ri-close-line</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-row id="progres-icon">
                  <v-col cols="12" md="6">
                    <!-- <div class="mb-2">Tanggal Import Mulai</div> -->
                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="start_date" prepend-inner-icon="ri-calendar-line" v-bind="attrs"
                          v-on="on"
                          @click:clear="start_date = null; start_date_temp = null"
                          placeholder="Masukkan Tanggal Awal disini" clearable
                          clear-icon="ri-close-circle-fill"></v-text-field>
                      </template>
                      <!-- :active-picker.sync="activePicker" -->
                      <v-date-picker v-model="start_date" @input="menu1 = false;"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01" @change="save1"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <!-- <div class="mb-2">Tanggal Import Akhir</div> -->
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="end_date" prepend-inner-icon="ri-calendar-line" v-bind="attrs"
                          v-on="on" placeholder="Masukkan Tanggal Akhir disini"
                          @click:clear="end_date = null; end_date_temp = null"
                          clearable clear-icon="ri-close-circle-fill"></v-text-field>
                      </template>
                      <!-- :active-picker.sync="activePicker2" -->
                      <v-date-picker v-model="end_date" @input="menu2 = false"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01" @change="save2"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <div class="mt-3 caption red--text font-italic">
                  Klik icon calendar untuk mengisi tanggal secara manual menggunakan format {{ convertExample("") }} atau {{ convertExample("backslash") }}
                </div>
              </v-col>
              <v-col cols="12" md="1" class="d-flex justify-start">
                <v-btn color="primary" dark class="rounded-lg" @click="filterData()">
                  <v-icon left>ri-search-line</v-icon>
                  Cari Data
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6" class="ml-7">
        <v-alert v-show="errorMessages.length > 0" type="error" class="mt-4">
          <div v-html="errorMessages" class="font-weight-medium subtitle-2" />
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col id="dt_program">
        <HistoryImportTable :loading="table_loading" :data="data" @refresh-list="fetch" @sorting="sorting"
          @downloadFile="download" />
      </v-col>
    </v-row>

    <v-snackbar top v-model="snackbar.state" color="primary" outlined :timeout="5000">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import HistoryImportTable from './components/Table.vue'
import moment from 'moment'

export default {
  components: {
    HistoryImportTable,
  },
  props: {
    propsReportType: {
      type: String,
      default: ""
    },
    autoRefreshList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      table_loading: false,
      showExpand: false,
      menu1: false,
      menu2: false,
      activePicker: false,
      activePicker2: false,
      errorMessages: "",
      start_date: null,
      end_date: null,
      start_date_temp: null,
      end_date_temp: null,
      searching: "",
      reportType: "",
      reportTypeList: [
        { text: "Semua", value: "" },
        { text: "UK", value: "uk" },
        { text: "TPM", value: "tpm" },
      ],
      snackbar: {
        state: false,
        text: "",
      },
      data: {},
      page: 1,
      limit: 10,
      dir: 'desc'
    }
  },
  watch: {
    reportType: {
      handler: function () {
        this.fetch()
      }
    },
    searching: {
      handler: function () {
        this.fetch()
      }
    },
    autoRefreshList: {
      handler: function () {
        if (this.autoRefreshList) {
          this.fetch()
        }
      }
    }
  },
  mounted() {
    this.$emit("page-changed", 0, { title: "Daftar History Import", link: "/import-history" });

    this.fetch()
  },
  methods: {
    convertExample(type) {
      return moment(new Date()).format(type === "backslash" ? "YYYY/MM/DD" : "YYYY-MM-DD");
    },
    save1(start_date) {
      this.$refs.menu1.save(start_date)
      // this.start_date = this.formatDate(start_date)
    },
    save2(end_date) {
      this.$refs.menu2.save(end_date)
      // this.end_date = this.formatDate(end_date)
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      // if(flag === 'start_date') this.start_date = `${day}-${month}-${year}`
      // else this.end_date = `${day}-${month}-${year}`
      return `${day}-${month}-${year}`
    },
    getLastDaysInMonth(m, y) {
      return m === 2 ? y & 3 || !(y % 25) && y & 15 ? 28 : 29 : 30 + (m + (m >> 3) & 1);
    },

    resetTgl() {
      this.start_date = ""
      this.end_date = ""
    },
    validateInputDate(start_date_arr, end_date_arr) {
      let isValid = true
      this.errorMessages = ""

      // console.log(start_date_arr, end_date_arr);


      // get last date in this month
      const lastDayStartInMonth = this.getLastDaysInMonth(Number(start_date_arr[1]), Number(start_date_arr[0]))
      const lastDayEndInMonth = this.getLastDaysInMonth(Number(end_date_arr[1]), Number(end_date_arr[0]))
      // const lastDayStartInMonth = new Date(Number(start_date_arr[0]), Number(start_date_arr[1]) - 1, 0).getDate() 
      // const lastDayEndInMonth = new Date(Number(end_date_arr[0]), Number(end_date_arr[1]) + 1, 0).getDate()

      // console.log(lastDayStartInMonth);
      // console.log(lastDayEndInMonth);


      // pengecekan tanggal tidak boleh lebih dari tanggal akhir bulan yang di input
      if (start_date_arr[2] > lastDayStartInMonth) {
        isValid = false
        this.errorMessages += `Tanggal mulai tidak boleh lebih besar dari ${lastDayStartInMonth}. <br/>`
      }

      // pengecekan tanggal tidak boleh lebih dari tanggal akhir bulan yang di input
      if (end_date_arr[2] > lastDayEndInMonth || end_date_arr[1] > 12) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih besar dari ${lastDayEndInMonth}. <br/>`
      }

      // pengecekan bulan tidak boleh lebih dari 12
      if (start_date_arr[1] > 12 || end_date_arr[1] > 12) {
        isValid = false
        this.errorMessages += `Bulan tidak boleh lebih besar dari 12 bulan. <br/>`
      }

      // for formating input 01-12-2024 or 01/12/2024 to 2024-12-01
      // const start_date_temp = `${start_date_arr[2]}-${start_date_arr[1]}-${start_date_arr[0]}`
      // const end_date_temp = `${end_date_arr[2]}-${end_date_arr[1]}-${end_date_arr[0]}`


      // for formating input 2024-12-01 or 2024/12/01 to 2024-12-01
      const start_date_temp = `${start_date_arr[0]}-${start_date_arr[1]}-${start_date_arr[2]}`
      const end_date_temp = `${end_date_arr[0]}-${end_date_arr[1]}-${end_date_arr[2]}`

      // pengecekan tanggal inputan dari hari ini
      const date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

      // pengecekan tanggal tidak boleh lebih dari hari ini
      if (start_date_temp > date_now) {
        isValid = false
        this.errorMessages += `Tanggal mulai tidak boleh lebih besar dari hari ini. <br/>`
      }

      if (end_date_temp > date_now) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih besar dari hari ini. <br/>`
      }

      // pengecekan tanggal akhir tidak boleh lebih kecil dari tanggal mulai
      if (end_date_temp < start_date_temp) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih kecil dari tanggal mulai. <br/>`
      }

      // pengecekan tahun tidak boleh lebih dari tahun ini
      const year_now = new Date().getFullYear()

      if (start_date_arr[2] > year_now) {
        isValid = false
        this.errorMessages += `Tahun mulai tidak boleh lebih besar dari tahun saat ini. <br/>`
      }

      if (end_date_arr[2] > year_now) {
        isValid = false
        this.errorMessages += `Tahun akhir tidak boleh lebih besar dari tahun saat ini. <br/>`
      }


      return isValid
    },
    filterData() {
      // if (this.start_date == null && this.end_date == null) {
      //   this.snackbar.text = 'Tanggal tidak boleh kosong';
      //   this.snackbar.state = true;
      //   return
      // }
      let start_date_arr = null
      let end_date_arr = null

      // console.table({startDate: `${this.start_date}`, endDate: `${this.end_date}`,url, data,})

      if (this.start_date && this.end_date) {
        // jika tanggal includes -
        if (this.start_date.includes("-")) start_date_arr = this.start_date.split("-")
        if (this.end_date.includes("-")) end_date_arr = this.end_date.split("-")

        // jika tanggal includes /
        if (this.start_date.includes("/")) start_date_arr = this.start_date.split("/")
        if (this.end_date.includes("/")) end_date_arr = this.end_date.split("/")

        // for formating input 01-12-2024 or 01/12/2024 to 2024-12-01
        // this.start_date_temp = `${start_date_arr[2]}-${start_date_arr[1]}-${start_date_arr[0]}`
        // this.end_date_temp = `${end_date_arr[2]}-${end_date_arr[1]}-${end_date_arr[0]}`


        // for formating input 2024-12-01 or 2024/12/01 to 2024-12-01
        this.start_date_temp = `${start_date_arr[0]}-${start_date_arr[1]}-${start_date_arr[2]}`
        this.end_date_temp = `${end_date_arr[0]}-${end_date_arr[1]}-${end_date_arr[2]}`

        // sending to validate format is arr: [01, 12, 2024] : is formating date 01-12-2024
        // sending to validate format is arr: [2024, 12, 01] : is formating date 2024-12-01
        if (!this.validateInputDate(start_date_arr, end_date_arr)) {
          this.submitting = false;
          this.snackbar.text = 'Periksa kembali tanggal yang Anda isikan';
          this.snackbar.state = true;
          return
        }

      }

      // this.showExpand = !this.showExpand

      this.fetch()
    },
    async fetch() {
      this.table_loading = true
      await this.axios.get("/users/v1/company/list-import-history", {
        params: {
          page: this.data.page,
          limit: this.data.limit,
          type: this.propsReportType,
          filename: this.searching,
          start_date: this.start_date_temp,
          end_date: this.end_date_temp,
          range_date_type: 'created_at',
          sort: 'created_at',
          sort_type: this.dir
        }
      })
        .then((res) => {
          this.reportType = this.propsReportType
          this.data = res.data.data
        })
        .catch((err) => {
          console.error(err);

        })
        .finally(() => {
          this.table_loading = false
        })
    },
    sorting(value) {
      if (value === true) this.dir = 'desc'
      else this.dir = 'asc'
      this.fetch()
    },
    async download(file) {
      // donwload from link
      let a = document.createElement('a');
      a.href = file.url;
      a.download = file.name;
      a.click();

    }
  }
}
</script>