<template>
  <div>
    <v-snackbar :color="notification.color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="white" v-bind="attrs" @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <div :style="$vuetify.breakpoint.name == 'xs' ? 'width:100%' : 'width: 30%'">
      <v-tabs v-model="tab" background-color="transparent" class="tab-import" grow>
        <v-tab @click="autoRefreshList = false">Import Nilai</v-tab>
        <v-tab @click="autoRefreshList = true">Riwayat</v-tab>
      </v-tabs>
    </div>
    <v-divider />


    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card class="rounded-lg">
          <v-card-title>
            Form Import Nilai UK
            <v-spacer></v-spacer>
            <a href="/file/template-import.xlsx" download>Download Template</a>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <!-- <ValidationObserver ref="observer"> -->
            <v-form>
              <!-- IMAGE UPLOAD -->
              <div v-if="fileUpload[0] || filepreview" class="text-right mb-1 py-0">
                <v-btn color="#d31145" icon depressed small @click="removeFile()">
                  <v-icon>ri-delete-bin-6-line</v-icon>
                </v-btn>
              </div>

              <div :class="[
                'dropZone',
                dragging ? 'dropZone-over' : 'cursor-pointer',
              ]" @dragenter="dragging = true" @dragleave="dragging = false">
                <div v-if="!fileUpload[0] && filepreview == ''" class="dropZone-info" @drag="onChange">
                  <span class="fa fa-cloud-upload dropZone-title"></span>
                  <div class="dropZone-upload-limit-info">
                    <div>Drag or Drop the file here</div>
                    <div>File harus dengan format .xlsx</div>
                    <div class="text-center mt-3">
                      <v-btn width="150" depressed dense> Browse File </v-btn>
                    </div>
                  </div>
                </div>
                <div v-else class="dropZone-info">
                  <span class="fa fa-cloud-upload dropZone-title"></span>
                  <div v-if="!process.upload">
                    <v-img height="200" contain class="mx-auto" :src="require('@/assets/images/excel_file.png')">
                    </v-img>
                    <div class="subtitle-2 font-weight-bold black--text">Nama File : {{ fileUpload[0].name }}</div>
                  </div>
                  <div v-if="process.upload">
                    <v-progress-linear indeterminate color="primary">
                    </v-progress-linear>
                    <span>
                      Uploading File . . .
                    </span>
                  </div>
                </div>
                <form ref="fileUploaded">
                  <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ref="inputFile"
                    type="file" @change="onChange" />
                </form>
              </div>

              <v-scroll-y-transition>
                <v-alert dense outlined text dismissible icon="ri-alert-line" close-icon="ri-close-line" color="error"
                  class="mb-1 mt-5" v-show="error.validation.length > 0">
                  <div class="subtitle-2" v-html="error.validation"></div>
                </v-alert>
              </v-scroll-y-transition>
              <!-- IMAGE UPLOAD -->

            </v-form>
            <!-- </ValidationObserver> -->
          </v-card-text>
          <v-card-actions class="pb-2 px-7">
            <v-spacer></v-spacer>
            <v-btn width="100" elevation="0" color="primary" class="text-capitalize white--text" :loading="process.run"
              :disabled="process.run" @click="save()">
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <HistoryImport propsReportType="uk" :autoRefreshList="autoRefreshList" />
      </v-tab-item>
    </v-tabs-items>


    <v-dialog v-model="showFeedback" width="800">
      <v-card>
        <section class="pa-4">
          <v-row>
            <v-col cols="">
              <v-alert color="warning" text>
                {{ currentImport }}
                <!-- /{{ feedbackDetail.length }} data belum berhasil diimport. -->
                <a class="text-decoration-underline" href="#" @click="showFeedbackDetail = !showFeedbackDetail">Lihat
                  detail.</a>
              </v-alert>

              <v-expand-transition>
                <section v-if="showFeedbackDetail" class="mb-4">
                  <v-radio-group class="mb-2" v-model="filterFeedback" row>
                    <v-row no-gutters align="center">
                      <div class="mr-4">Status:</div>
                      <v-radio class="mr-4" label="Semua" :value="0"></v-radio>
                      <v-radio class="mr-4" label="Sukses" :value="1"></v-radio>
                      <v-radio class="mr-4" label="Gagal" :value="2"></v-radio>
                    </v-row>
                  </v-radio-group>

                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td class="font-weight-medium pa-2">No</td>
                        <td class="font-weight-medium pa-2">License</td>
                        <td class="font-weight-medium pa-2">Status</td>
                        <td class="font-weight-medium pa-2">Message</td>
                      </tr>
                      <tr v-for="(item, index) in filteredFeedbackDetail" :key="index">
                        <td class="pa-2 text-center">{{ index + 1 }}</td>
                        <td class="pa-2">{{ item.license }}</td>
                        <td class="pa-2">
                          <v-chip v-if="item.status" small outlined color="success">Sukses</v-chip>
                          <v-chip v-else small outlined color="error">Gagal</v-chip>
                        </td>
                        <td class="pa-2">{{ item.message }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </section>
              </v-expand-transition>

              <div class="d-flex justify-end">
                <v-btn small outlined color="primary" @click="showFeedback = false; feedbackDetail = []">Tutup</v-btn>
              </div>
            </v-col>
          </v-row>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import HistoryImport from "./HistoryImport.vue";
export default {
  components: {
    HistoryImport,
  },
  data() {
    return {
      tab: 0,
      autoRefreshList: false,
      showFeedback: false,
      showFeedbackDetail: false,
      filterFeedback: 0,
      feedbackDetail: [],

      file: [],
      errors: {},
      draging: false,
      /**
       * Attachment
       */
      dragging: false,
      fileUpload: [],
      filepreview: '',
      error: {
        validation: "",
      },
      process: {
        run: false,
        upload: true,
      },
      file_excel: [],
      is_active: false,
      notification: {
        state: false,
        text: "",
        color: ""
      },
    };
  },
  computed: {
    FormartedDate() {
      // format date with moment format
      return moment(this.form.maintenance_date).format("DD MMMM YYYY");
    },
    currentImport() {
      let total = 0;
      let status = []
      this.feedbackDetail.reduce((car, cur) => {
        status.push(cur.status)
        // console.log(car);
        total = cur.status ? car + 1 : car + 1;
        return total
      }, 0)
      // console.log(total);
      // console.log(status);

      return `${total}/${this.feedbackDetail.length}  ${status.includes(false) ? 'data belum berhasil diimport.' : 'data berhasil diimport.'}`
    },
    filteredFeedbackDetail() {
      return this.feedbackDetail.filter((v) => {
        if (this.filterFeedback == 0) return true;
        if (this.filterFeedback == 1) return v.status;
        if (this.filterFeedback == 2) return !v.status;
      });
    },
    user() {
      return JSON.parse(localStorage.getItem("ytc_auth"));
    }
  },
  watch: {
    showFeedback(val) {
      if (!val) {
        this.showFeedbackDetail = false;
        this.filterFeedback = 0;
      }
    },
  },
  mounted() {
    this.$emit("page-changed", 0, { title: "Import Nilai UK", link: "/import" });
  },
  methods: {
    removeFile() {
      this.$refs.fileUploaded.reset();
      this.fileUpload = [];
      this.filepreview = "";
      this.file_excel = [];
      this.$refs.inputFile.value = null;
    },
    onChange(e) {
      let src = {
        url: null,
        title: null,
        alt: null,
        description: null,
        caption: null,
      }
      var files = e.target.files || e.dataTransfer.files;
      let selectedFiles = e.target.files[0] || e.dataTransfer.files[0];

      if (this.draging) {
        this.draging = false;
        this.filepreview = URL.createObjectURL(selectedFiles);
        src.url = URL.createObjectURL(selectedFiles);
        src.title = selectedFiles.name;
        src.alt = selectedFiles.name;
        src.file = selectedFiles;
      } else {
        this.filepreview = URL.createObjectURL(e.target.files[0]);
        src.url = URL.createObjectURL(e.target.files[0]);
        src.title = e.target.files[0].name;
        src.alt = e.target.files[0].name;
        src.file = e.target.files[0];
      }

      src.source = "upload";

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.fileUpload = files;
      setTimeout(() => {
        this.process.upload = false
      }, 2000)
    },
    validation() {
      var error = this.error,
        valid = true;

      if (this.fileUpload.length == 0) {
        error.validation = "File Tidak Boleh Kosong";
        valid = false;
      } else {
        error.validation = "";
      }

      return valid;
    },
    async save() {
      this.process.run = true;
      const Valids = await this.validation()
      if (Valids) {
        let formData = new FormData();
        formData.append("file", this.fileUpload[0])

        await this.axios.put(`/users/v1/trainer/import-feedback-uk-mr`, formData)
          .then(response => {
            this.showFeedback = true;
            this.showFeedbackDetail = false;
            this.filterFeedback = 0;
            this.feedbackDetail = response.data.data;

            let res = response.data
            if (res.status == 200) {
              this.removeFile()
              this.process.run = false
              this.notification.state = true;
              this.notification.color = "#4CAF50";
              this.notification.text = "Import nilai berhasil dilakukan";
            } else {
              this.removeFile()
              this.process.run = false
            }
          }).catch(error => {
            if (error.response) {
              // console.log(error.response);
              let errorCode = error.response.data.status
              let errorData = error.response.data.validation;

              if (errorCode == 400) {
                window.scrollTo(0, 0);
                this.process.run = false;
                this.response = ""
                if (errorData != null && Object.keys(errorData).length > 0) {
                  Object.keys(errorData).forEach(item => {
                    this.error.validation += errorData[item] + `</br>`;
                  })
                } else {
                  this.error.validation = error.response.data.message
                }
                this.removeFile()
              }
            }
          })
      } else {
        this.process.run = false;
      }
    },
  },
};
</script>

<style>
.dropZone {
  width: 100%;
  min-height: 400px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #652065;
}

.dropZone:hover .dropZone-title {
  color: #652065;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>