<template>
  <v-row justify="center">
    <v-col v-if="isPaketEmpty" cols="4">
      <v-row class="flex-column" align-content="center">
        <v-col>
          <v-img src="@/assets/images/data_empty.png" />
        </v-col>
        <v-col>
          <div class="text-center title">Tidak ada program</div>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>
      <v-row align="center">

        <v-col id="search" lg="12" class="justify-end d-flex align-center">
          <v-text-field
            append-icon="$search"
            class="pt-0 mt-0 mr-4"
            placeholder="Cari program . . ."
            v-model="name_program"
            @keydown.enter="fetchAll"
            outlined
            dense>
            <template v-slot:append v-if="name_program">
              <v-icon @click="name_program = '';fetchAll()">ri-close-line</v-icon>
            </template>
          </v-text-field>
          <v-btn
            small
            color="primary mr-3"
            elevation="0"
            @click="fetchAll">
            Search
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            offset-y
            origin="center center"
            transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mr-3"
                dark
                small
                v-bind="attrs"
                v-on="on">
                <v-icon class="mr-2">ri-download-line </v-icon> Export
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="openDialogFilter('program')">
                <v-list-item-content>
                  <v-list-item-title>
                    Export Program
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openDialogFilter('epm')">
                <v-list-item-content>
                  <v-list-item-title>
                    Export TPM & UK
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openDialogFilter('attachment_epm')">
                <v-list-item-content>
                  <v-list-item-title>
                    Export Attachment TPM & UK
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openDialogFilter('score_epm')">
                <v-list-item-content>
                  <v-list-item-title>
                    Export Nilai TPM & UK
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            small
            color="primary"
            elevation="0"
            class="mr-2"
            @click="dialog=true;listProgram()"
          >
            <v-icon class="mr-2">ri-download-line </v-icon> Export
          </v-btn> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col id="dt_program">
          <program-all
            :loading="table_loading"
            :data="data"
            @refresh-list="fetchProgram"
            @remove="remove"
            @update="update"
            @sorting="sorting"
          ></program-all>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="650px" persistent scrollable>
        <v-card>
          <v-card-title>
            <span>  {{ dialogState === 'epm' ? 'Export TPM & UK' : dialogState === 'attachment_epm' ? 'Export Attachment TPM & UK' : dialogState === 'score_epm' ? 'Export Nilai TPM & UK' :  'Export Program'  }} </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert v-show="errorMessages.length > 0" type="error" class="mt-4">
              <div v-html="errorMessages" class="font-weight-medium subtitle-2" />
            </v-alert>
            <v-form ref="form" v-model="valid">
              <!-- <v-col cols="12" v-if="dialogState == 'program'">
                <div class="mb-2">Nama Antrean</div>
                <v-text-field
                  v-model="name_of_export"
                  dense
                  filled
                  multiple
                  chips
                  :rules="[...rules('Nama Antrian', 'required'), validate]"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <div class="mb-2">Program</div>
                <v-autocomplete
                  v-model="program_"
                  :items="list_program"
                  :item-text="'title'"
                  :item-value="'id'"
                  dense
                  filled
                  multiple
                  chips
                  :rules="[...rules('Program', 'required_array'), validate]"
                ></v-autocomplete>
              </v-col>
              <v-col id="progres-icon" cols="12">
                <v-row>
                  <v-col cols="12">
                    <div class="mb-2">Tanggal</div>
                    <v-select
                      v-model="sort"
                      :items="sorts"
                      :item-text="'title'"
                      :item-value="'name'"
                      :rules="[...rules('Tanggal', 'required'), validate]"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-show="sort" id="progres-icon" cols="12">
                <v-row>
                  <v-col cols="12" md="5">
                    <div class="mb-2">Tanggal Mulai</div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="start_date"
                          prepend-inner-icon="ri-calendar-line"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="start_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          placeholder="Masukkan Tanggal disini"
                          :rules="[...rules('Tanggal Mulai', 'required'), validate]"
                          clearable
                          clear-icon="ri-close-circle-fill"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="start_date"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="5">
                    <div class="mb-2">Tanggal Akhir</div>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="end_date"
                          prepend-inner-icon="ri-calendar-line"                          
                          v-bind="attrs"
                          v-on="on"
                          placeholder="Masukkan Tanggal disini"
                          @click:clear="end_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          :rules="[...rules('Tanggal Akhir', 'required'), validate]"
                          clearable
                          clear-icon="ri-close-circle-fill"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="end_date"
                        :active-picker.sync="activePicker2"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save2"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="2" class="pl-0">
                    <div class="mb-2 white--text">Tanggal</div>                    
                    <v-btn color="primary" elevation="0" @click="resetTgl()" :block="$vuetify.breakpoint.name !== 'xs'">
                      {{ $vuetify.breakpoint.name !== 'xs' ? 'Reset' : 'Reset Tanggal' }}
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="mt-3 caption red--text font-italic">
                  Klik icon calendar untuk mengisi tanggal secara manual menggunakan format {{ convertExample("") }} atau {{ convertExample("backslash") }}
                </div>
              </v-col>                  
              <v-col v-show="sort === 'created_at'" id="progres-icon" cols="12">
                <v-row>
                  <v-col cols="12">
                    <div class="mb-2">Progress</div>
                    <v-select
                      v-model="progress"
                      :items="progs"
                      :item-text="'name'"
                      :item-value="'id'"
                    ></v-select>
                    <!-- <v-text-field dense v-model.number="progress_start" type="number" append-icon="ri-percent-fill"></v-text-field> -->
                  </v-col>
                  <!-- <v-col cols="6">
                    <div class="mb-2">Progress (Batas Maksimal)</div>
                    <v-text-field dense v-model.number="progress_end" type="number" append-icon="ri-percent-fill"></v-text-field>
                  </v-col> -->
                </v-row>
              </v-col>                          
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="dialog=false">
              Batal
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="handleExportData"
            >
              {{ dialogState === 'epm' ? 'Export TPM & UK' : dialogState === 'attachment_epm' ? 'Export Attachment TPM & UK' : dialogState === 'score_epm' ? 'Export Nilai TPM & UK' : 'Export Program'  }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="is_delete.show" max-width="600px">
        <v-card>
          <v-card-title>
            <span> <v-icon>$warning</v-icon> Hapus Program </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              Apakah anda ingin menghapus program <strong class="text-capitalize">{{ this.prog.title }} </strong>ini ?
            </v-card-text>            
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="cancel()">
              Batal
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="hapus(is_delete.id)"
            >
              Ya, Hapus!
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="is_change.show" max-width="600px">
        <v-card>
          <v-card-title>
            <span> <v-icon>$warning</v-icon> Update status </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text v-if="prog.status === 'draft' || prog.status === 'archive'">
              Apakah anda ingin mengubah status program dari  {{ this.prog.status }} menjadi Publish  ?
            </v-card-text>
            <v-card-text v-else>
              Apakah anda ingin mengubah status program dari  {{ this.prog.status }} menjadi archive  ?
            </v-card-text>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="cancelChange()">
              Batal
            </v-btn>
            <v-btn
              v-if="prog.status =='draft' || prog.status === 'archive'"
              class="white--text"
              color="primary"
              small
              elevation="0"
              @click="updatePublish(is_change.id)"
            >
              Ya, Ubah!
            </v-btn>
            <v-btn
              v-else
              class="white--text"
              color="primary"
              small
              elevation="0"
              @click="updateArchive(is_change.id)"
            >
              Ya. Ubah!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>      
      <v-snackbar 
        top 
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>      
    </v-col>
  </v-row>
</template>

<script>
import ProgramAll from "./components/Table";
// import ProgramPublish from "./components/TablePublish";
// import ProgramDraft from "./components/TableDraft";
// import ProgramArchive from "./components/TableArchive";
import { mapGetters} from 'vuex';
import validationMixin from "../_mixins/validation";
import moment from "moment";
// import downloadExcel from "vue-json-excel";


export default {
  name: "program-list",
  mixins: [validationMixin],
  components: { 
    ProgramAll,
    // ProgramPublish,
    // ProgramDraft, 
    // ProgramArchive 
  },
  data: () => ({
    valid: true,
    errorMessages: "",
    newPaket: "",
    name_of_export:"",
    loading:false,
    all: {},
    publishes:{},
    draft:{},
    tabPaket: null,
    submitting:false,
    table_loading:false,
    is_delete: {
      show:false,
      id:''
    },
    snackbar: {
        state: false,
        text: "",
      },
    timeout:5000,
    is_change:{
      show:false,
      id:''
    },
    new_status: "",
    new_items:"",
    prog:{}, 
    name_program:"",
    dir:'',
    dirPublish:'',

    list_program:[],
    program_:[],
    progress_end:null,
    progress_start:null,
    dialog:false,
    dialogState:'',
    activePicker: null,
    start_date: null,
    menu: false,
    activePicker2: null,
    end_date: null,
    menu2: false,
    progress:'',
    sort:'',
    json_fields:{
      'Nama Program':'program.title',
    },
    json_data:{},    
    progs:[
      {
        id:'1',
        name:'All'
      },
      {
        id:'2',
        name:'100%'
      },
    ],
    sorts:[
      {
        title:'Tanggal Redeem',
        name:'created_at'
      },
      {
        title:'Tanggal Penyelesaian',
        name:'end_test_at'
      },
    ],
    url_:''
    // disabledFn: {
    //     customPredictor(date) {
    //       if (date.getDate() % 3 === 0) {
    //         return true;
    //       }
    //     }        
    //   },
  }),
  computed: {
    isPaketEmpty: () => false,
    ...mapGetters({ data: "program/data", params: "program/params" }),    
    ...mapGetters({ dataPublish: "program/dataPublish", paramsPublish: "program/paramsPublish" }),    
    // ...mapGetters("program", ["list", "page", "limit", "total"]),P
    // ...mapGetters("program", ["listPublish", "pagePublish", "limitPublish", "totalPublish"]),
    ...mapGetters("program", ["listDraft", "pageDraft", "limitDraft", "totalDraft"]),
    ...mapGetters("program", ["listArchive", "pageArchive", "limitArchive", "totalArchive"]),

    validate() {
      return !this.errors || this.errors.message;
    },

    // computed_search() {
    //   let q = {};
    //     q = this.name_program;
    //   return q;
    // },
  },
  watch: {
    // menu (val) {
    //   val && setTimeout(() => (this.activePicker = 'YEAR'))
    // },
    // menu2 (val) {
    //   val && setTimeout(() => (this.activePicker2 = 'YEAR'))
    // },
    progress_start(newVal){
      if(newVal === '') {
        this.progress_start = null;
      }
    },
    progress_end(newVal){
      if(newVal === '') {
        this.progress_end = null;
      }
    },
  },
  created() {
    
  },
  mounted() {
    this.$emit("page-changed", 0, { title: "Daftar Program", link: "/program" });

    this.fetchAll();
  },
  methods: {
    convertExample(type) {
      return moment(new Date()).format(type === "backslash" ? "YYYY/MM/DD" : "YYYY-MM-DD");
    },
    openDialogFilter(state) {
      this.dialog=true;
      this.dialogState=state;
      this.start_date= null;
      this.end_date= null;
      this.progress= null;
      this.sort= null;
      this.progress_start= null;
      this.progress_end= null;
      this.program_=[];
      this.errorMessages= '';
      this.listProgram()
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      // if(flag === 'start_date') this.start_date = `${day}-${month}-${year}`
      // else this.end_date = `${day}-${month}-${year}`
      return `${day}-${month}-${year}`
    },
    listProgram() {
      this.axios
        .get("/program/v1/trainer/list?status=publish&limit=-1")
        .then((response) => {
          let res = response.data.data.list;
          this.list_program = res;
        });
    },
    save (start_date) {
      this.$refs.menu.save(start_date)
      // this.start_date = this.formatDate(start_date)
    },
    save2 (end_date) {
      this.$refs.menu2.save(end_date)
      // this.end_date = this.formatDate(end_date)
    },
    handleExportData() {
      
      this.submitting=true;
      if (this.progress === '1') {
        this.progress_start=0;
        this.progress_end=100;
      }else if (this.progress === '2') {
        this.progress_start=100;
        this.progress_end=100;
      }else{
        this.progress_start=null;
        this.progress_end=null;
      }

      const data = {
        id:this.program_,
        // name: this.name_of_export
      }

      switch (this.dialogState) {
        case "epm":
          this.ExportDataProgram(`users/v1/export/member_redeem_epm`, data)
          break;
        case "attachment_epm":
          this.ExportDataProgram(`users/v1/export/member_redeem_attachment`, data)
          break;
        case "score_epm":
          this.ExportDataProgram(`users/v1/export/member_redeem_uk_epm`, data)
          break;
        default:
          this.ExportDataProgram(`users/v1/export/member_redeem`, data)
          break;
      }
    },

    getLastDaysInMonth(m, y) {
      return m===2 ? y & 3 || !(y%25) && y & 15 ? 28 : 29 : 30 + (m+(m>>3)&1);
    },

    validateInputDate(start_date_arr, end_date_arr) {
      let isValid = true
      this.errorMessages = ""

      // console.log(start_date_arr, end_date_arr);


      // get last date in input month
      const lastDayStartInMonth = this.getLastDaysInMonth(Number(start_date_arr[1]), Number(start_date_arr[0]))
      const lastDayEndInMonth = this.getLastDaysInMonth(Number(end_date_arr[1]), Number(end_date_arr[0]))
      // const lastDayStartInMonth = new Date(Number(start_date_arr[0]), Number(start_date_arr[1]) - 1, 0).getDate() 
      // const lastDayEndInMonth = new Date(Number(end_date_arr[0]), Number(end_date_arr[1]) + 1, 0).getDate()

      // console.log(lastDayStartInMonth);
      // console.log(lastDayEndInMonth);


      // pengecekan tanggal tidak boleh lebih dari tanggal akhir bulan yang di input
      if (start_date_arr[2] > lastDayStartInMonth) {
        isValid = false
        this.errorMessages += `Tanggal mulai tidak boleh lebih besar dari ${lastDayStartInMonth}. <br/>`
      }

      // pengecekan tanggal tidak boleh lebih dari tanggal akhir bulan yang di input
      if (end_date_arr[2] > lastDayEndInMonth || end_date_arr[1] > 12) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih besar dari ${lastDayEndInMonth}. <br/>`
      }

      // pengecekan bulan tidak boleh lebih dari 12
      if (start_date_arr[1] > 12 || end_date_arr[1] > 12) {
        isValid = false
        this.errorMessages += `Bulan tidak boleh lebih besar dari 12 bulan. <br/>`
      }

      // for formating input 01-12-2024 or 01/12/2024 to 2024-12-01
      // const start_date_temp = `${start_date_arr[2]}-${start_date_arr[1]}-${start_date_arr[0]}`
      // const end_date_temp = `${end_date_arr[2]}-${end_date_arr[1]}-${end_date_arr[0]}`


      // for formating input 2024-12-01 or 2024/12/01 to 2024-12-01
      const start_date_temp = `${start_date_arr[0]}-${start_date_arr[1]}-${start_date_arr[2]}`
      const end_date_temp = `${end_date_arr[0]}-${end_date_arr[1]}-${end_date_arr[2]}`

      // pengecekan tanggal inputan dari hari ini
      const date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

      // pengecekan tanggal tidak boleh lebih dari hari ini
      if (start_date_temp > date_now) {
        isValid = false
        this.errorMessages += `Tanggal mulai tidak boleh lebih besar dari hari ini. <br/>`
      }

      if (end_date_temp > date_now) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih besar dari hari ini. <br/>`
      }

      // pengecekan tanggal akhir tidak boleh lebih kecil dari tanggal mulai
      if (end_date_temp < start_date_temp) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih kecil dari tanggal mulai. <br/>`
      }

      // pengecekan tahun tidak boleh lebih dari tahun ini
      const year_now = new Date().getFullYear()

      if (start_date_arr[2] > year_now) {
        isValid = false
        this.errorMessages += `Tahun mulai tidak boleh lebih besar dari tahun saat ini. <br/>`
      }

      if (end_date_arr[2] > year_now) {
        isValid = false
        this.errorMessages += `Tahun akhir tidak boleh lebih besar dari tahun saat ini. <br/>`
      }


      return isValid
    },


    async ExportDataProgram(url, data) {
      
      if(this.$refs.form.validate()) {
        let start_date_arr = null
        let end_date_arr = null

        // console.table({startDate: `${this.start_date}`, endDate: `${this.end_date}`,url, data,})

        // jika tanggal includes -
        if (this.start_date.includes("-")) start_date_arr = this.start_date.split("-")
        if (this.end_date.includes("-")) end_date_arr = this.end_date.split("-")

        // jika tanggal includes /
        if (this.start_date.includes("/")) start_date_arr = this.start_date.split("/")
        if (this.end_date.includes("/")) end_date_arr = this.end_date.split("/")

        // for formating input 01-12-2024 or 01/12/2024 to 2024-12-01
        // this.start_date_temp = `${start_date_arr[2]}-${start_date_arr[1]}-${start_date_arr[0]}`
        // this.end_date_temp = `${end_date_arr[2]}-${end_date_arr[1]}-${end_date_arr[0]}`


        // for formating input 2024-12-01 or 2024/12/01 to 2024-12-01
        const start_date_temp = `${start_date_arr[0]}-${start_date_arr[1]}-${start_date_arr[2]}`
        const end_date_temp = `${end_date_arr[0]}-${end_date_arr[1]}-${end_date_arr[2]}`

        // sending to validate format is arr: [01, 12, 2024] : is formating date 01-12-2024
        // sending to validate format is arr: [2024, 12, 01] : is formating date 2024-12-01
        if (!this.validateInputDate(start_date_arr, end_date_arr)) {
          this.submitting = false;
          this.snackbar.text = 'Periksa kembali tanggal yang Anda isikan';
          this.snackbar.state = true;
          return
        }

        await this.axios
        .post(`${url}`, data, {
          params: {
            sort: this.sort,
            range_date_type: this.sort,
            limit: -1,
            progress_start: this.progress_start,
            progress_end: this.progress_end,
            start_date: start_date_temp,
            end_date: end_date_temp
          }
        })
        .then((res) => {
          const {data: resData} = res.data
          this.snackbar.text = `Data berhasil di export`;
          this.snackbar.state = true;
          this.submitting=false;
          this.dialog=false;
          this.program_=[];
          this.sort='';
          this.progress_start='';
          this.progress_end='';
          this.start_date=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
          this.end_date=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

          this.$refs.form.reset()
          if(resData.public_url) {
            this.forceFileDownload(resData)
          }else {
            this.snackbar.text = 'Data file export tidak tersedia';
            this.snackbar.state = true;
          }
        }).catch((res) => {
          this.submitting=false;
          this.snackbar.text = res.response.data.message;
          this.snackbar.state = true; 
        })
      }else {
        this.submitting=false;
        this.snackbar.text = 'Periksa kembali data yang Anda isikan';
        this.snackbar.state = true;
      }
    },

    forceFileDownload(data) {
      const link = document.createElement('a')
      link.href = data.public_url
      link.setAttribute('download', `${data.title}.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()
    },
    resetTgl(){
      this.start_date=null;
      this.end_date=null;
      this.$refs.form.resetValidation()
    },     
    fetchAll(){
      this.fetchProgram();
    },
    fetchProgram() {
      this.table_loading = true;      
      this.$store.dispatch("program/list",{q: this.name_program}).finally(() => {
        this.table_loading = false;
      });
    },
    sorting(value) {
      if (value === true) {
        this.dir='desc'
      }else{this.dir='asc'}
      this.table_loading = true;    
      this.$store.dispatch("program/list",{q: this.name_program, sort:'total_participants',sort_type:this.dir}).finally(() => {
        this.table_loading = false;
      });         
    },
    update(program) {      
      this.is_change.show=true;
      this.is_change.id=program.id;
      this.prog=program;    
    },
    remove(program) {
      this.is_delete.show=true;
      this.is_delete.id=program.id;
      this.prog=program;      
    },
    edit(){},
    // update(item, status){
    //   this.new_items = item;
    //   this.new_status = status;
    // },
    cancel() {
      this.is_delete.show = false;
    },
    cancelChange() {
      this.is_change.show = false;
    },
    cancelDownloadExport() {
      this.dialog = false;
      this.program_ = [];
      this.sort=''
      this.start_date = '';
      this.end_date = '';
      this.progress = '';
    },
    hapus(id){
      this.is_delete.id=id;
      // console.log('id delete',this.is_delete.id);
      this.submitting=true;
      this.axios
        .delete(`/program/v1/company/delete/${this.is_delete.id}`)
        .then((res) =>{
          this.submitting=false;
          this.is_delete.show=false;
          if (res.status == 200) {
            this.snackbar.text = `Program berhasil dihapus`;
            this.snackbar.state = true;
            this.fetchAll();

          }else{
            this.snackbar.text = res.message;
            this.snackbar.state = true;
          } 
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updatePublish(id){
      this.is_change.id=id;
      this.submitting=true;
      const data ={
        status:'publish'
      }
      this.axios
        .put(`/program/v1/company/update-status/${this.is_change.id}`, data)
        .then((res) =>{
          this.submitting=false;
          this.is_delete.show=false;
          if (res.status == 200) {
            this.snackbar.text = `Status program berhasil diubah`;
            this.snackbar.state = true;
            this.is_change.show=false;            
            this.fetchAll();
          }else{
            this.snackbar.text = res.message;
            this.snackbar.state = true;
          } 
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateArchive(id){
      this.is_change.id=id;
      this.submitting=true;
      const data ={
        status:'archive'
      }
      this.axios
        .put(`/program/v1/company/update-status/${this.is_change.id}`, data)
        .then((res) =>{
          this.submitting=false;
          this.is_delete.show=false;
          if (res.status == 200) {
            this.snackbar.text = `Status program berhasil diubah`;
            this.snackbar.state = true;
            this.fetchAll();
            this.is_change.show=false;

          }else{
            this.snackbar.text = res.message;
            this.snackbar.state = true;
          } 
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
};
</script>
<style type="text/css">
  .v-select.v-input--dense .v-chip {
    margin:8px 4px 10px 4px;
}
</style>