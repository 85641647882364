<template>
  <div>
    <v-row>
      <v-col id="dt_program">
        <HistoryImportTable
          :loading="table_loading"
          :data="data"
          @refresh-list="fetch"
          @sorting="sorting"
          @downloadFile="download"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import HistoryImportTable from './components/Table.vue'
export default {
  components: {
    HistoryImportTable,
  },
  data() {
    return {
      table_loading: false,
      data: {},
      dir: 'desc'
    }
  },
  mounted() {
    this.$emit("page-changed", 0, { title: "Daftar History Import TPM", link: "/import-history-tpm" });

    this.fetch()
  },
  methods: {
    async fetch() {
      this.table_loading = true
      await this.axios.get("/users/v1/company/list-import-history", {
        params: {
          page: this.data.page,
          limit: this.data.limit,
          type: "tpm",
          sort: 'created_at',
          sort_type:this.dir
        }
      })
        .then((res) => {
          this.data = res.data.data
        })
        .catch((err) => {
          console.error(err);
          
        })
        .finally(() => {
          this.table_loading = false
        })
    },
    sorting(value) {
      if (value === true) this.dir='desc'
      else this.dir='asc'
      this.fetch()
    },
    async download(file) {
      // donwload from link
      let a = document.createElement('a');
      a.href = file.url;
      a.download = file.name;
      a.click();

    }
  }
}
</script>