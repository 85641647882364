<template>
  <v-data-table
    :loading="isLoading"
    class="transparent"
    :headers="headers"
    :item-key="'id'"
    :items="data.list"
    :items-per-page.sync="data.limit"
    :page.sync="data.page"
    :mobile-breakpoint="0"
    loading-text="Loading... Please wait!"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :footer-props="{
      'items-per-page-text':'Row per page : '
    }"
    :server-items-length="data.total"
  >
    <template v-slot:header.total="{ header }">
      <th v-if="sortDesc===false" @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-up-line</v-icon></th>
      <th v-else @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-down-line</v-icon></th>
    </template>
    <template slot="item" slot-scope="{ item }">
      <tr class="rounded-lg flat-b-1">
        <td class="rounded-l-lg white" style="width: 20%">
          {{ formatDatetime(item.created_at) }}
        </td>
        <td class="white primary--text font-weight-medium">
          {{ item.filename }}
        </td>
        <td class="white text-uppercase font-weight-bold">
          {{ item.type }}
        </td>
        <td class="white text-capitalize">
          <v-chip
            v-if="item.status == 'success'"
            :ripple="false"
            class="pa-1 text-capitalize"
            dark
            color="success"
            label
            small
            v-text="item.status"
          ></v-chip>
          <v-chip
            v-else
            :ripple="false"
            class="pa-1 text-capitalize"
            dark
            color="red"
            label
            small
            v-text="item.status"
          ></v-chip>
        </td>
        <td class="white text-capitalize">
          <v-btn
            outlined
            small
            color="primary"
            class="font-weight-medium rounded-xxl"
            @click="$emit('downloadFile', item.file)">
            <v-icon size="18">ri-download-line</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import moment from 'moment';
export default {
  name: "history-import-table",
  props: {
    data: { required: true, type: Object, default: () => ({}) },
    loading: { type: Boolean, default: () => false },

  },
  data: () => ({
    sortBy: "total",
    sortDesc: false,
    headers: [
      { text: "Waktu Import", class: "text-no-wrap", sortable: false, },
      { text: "Nama File Import", class: "text-no-wrap", sortable: false, },
      { text: "Tipe Penilaian", class: "text-no-wrap", sortable: false, },
      { text: "Status", class: "text-no-wrap", sortable: false, },
      { text: "Actions", class: "text-no-wrap", sortable: false, },
    ],
    table_loading: false,
    
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
  },
  methods: {
    formatDatetime(datetime) {
      return datetime ? moment(datetime).format("DD MMM YYYY HH:mm") : '-'
    },
    sortCalories() {
      if (this.sortDesc === false) {
        this.sortDesc = true;
      }else{
        this.sortDesc = false;        
      }
      // console.log('sort',this.sortDesc)
      this.$emit("sorting",this.sortDesc);
    },
    paginate: (current, total) => {
      if (current > total) {
        throw new Error("Current page is larger than total");
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? "..." : i + current - 2;
              case 4:
                return current < total - 2 ? "..." : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? "..." : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? "..." : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
  }
};
</script>
<style>
</style>