<template>
  <v-row justify="center">
    <v-col v-if="isPaketEmpty" cols="4">
      <v-row class="flex-column" align-content="center">
        <v-col>
          <v-img src="@/assets/images/data_empty.png" />
        </v-col>
        <v-col>
          <div class="text-center title">Tidak ada program</div>
        </v-col>
        <div class="text-center subtitle body-1">
          klik tombol di bawah ini untuk menambahkan program baru
        </div>
        <v-col class="text-center">
          <v-btn color="primary" elevation="0">
            <v-icon>ri-add-line</v-icon> Program baru
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>
      <v-row>
        <v-col id="search" class="justify-end d-flex align-center">
          <!-- <v-menu bottom offset-y origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="white--text mr-2" color="teal" small :disabled="process.run"
                :loading="process.export">
                <v-icon left>ri-file-excel-line</v-icon>
                Export
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="handleExportData('attachment_epm')">
                <v-list-item-content>
                  <v-list-item-title>
                    Export Attachment TPM & UK
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handleExportData('score_epm')">
                <v-list-item-content>
                  <v-list-item-title>
                    Export Nilai TPM & UK
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <v-btn class="white--text" color="primary" small :disabled="process.export" :loading="process.run"
            @click="showFilter = true">
            <v-icon left>ri-filter-3-line</v-icon>
            Filter
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="dt_program">
          <!-- <program-all
						:loading="table_loading"
						@change="change"
						@detail="detail"
						@refresh-list="fetchAll"
						:data="{ perPage: limit, list, page, total }"
					></program-all> -->
          <program-all :loading="table_loading" :data="data" @change="change" @detail="detail" @sorting="sorting"
            @voucher="voucher" @refresh-list="fetchPeserta" />
        </v-col>
      </v-row>

      <v-dialog v-model="is_change.show" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            <span> Update User </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-col cols="12">
                <div class="mb-2">Nama</div>
                <v-text-field required filled hide-details="auto" v-model="member.fullname"
                  class="border-0"></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="mb-2">Nomor Whatsapp</div>
                <v-text-field required filled hide-details="auto" v-model="member.phone" class="border-0"
                  readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="mb-2">Email</div>
                <v-text-field required filled hide-details="auto" v-model="member.email"
                  class="border-0"></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="mb-2">
                  <span class="font-weight-medium">Tanggal Lahir</span>
                </div>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" elevation="0">
                  <v-date-picker ref="picker" v-model="member.date_of_birth" min="1950-01-01"
                    :max="new Date().toISOString()" @change="save" outlined required></v-date-picker>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="member.date_of_birth" append-icon="ri-calendar-line" :rules="dateRules"
                      readonly outlined v-bind="attrs" v-on="on" class=""></v-text-field>
                  </template>
                </v-menu>
              </v-col>
              <!-- <v-col cols="12">
								<div class="mb-2">Nomor Kartu Prakerja</div>
								<v-text-field required filled hide-details="auto" v-model="code" class="border-0"></v-text-field>
							</v-col> -->
            </v-card-text>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="cancel()">
              Batal
            </v-btn>
            <v-btn class="white--text" color="primary" small elevation="0" :loading="submitting"
              @click="editMember(is_change.id)">
              Ya, Ubah!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="is_list.show" max-width="600px" scrollable persistent>
        <v-card>
          <v-card-title>
            <span> Detail Aktivitas </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text id="act-topic">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, index) in topics" :key="index">
                <v-expansion-panel-header class="font-weight-bold">
                  {{ item.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense class="col-12 pa-0">
                    <v-list-item-group>
                      <v-divider></v-divider>
                      <v-list-item v-for="(act, i) in item.activities" :key="i" class="pl-0">
                        <v-list-item-content>
                          <div class="my-1 d-flex">
                            <v-icon v-if="act.is_complete === true" class="mt-0 mr-1" color="green darken-3"
                              small>ri-checkbox-circle-fill</v-icon>
                            <div class="mb-1 font-weight-medium text-capitalize">
                              {{ act.title }}
                            </div>
                          </div>
                          <div v-if="
                            act.type == 'evaluasi_praktek_mandiri' &&
                            act.is_complete === true
                          ">
                            <a :href="act.upload.file.url" target="_blank" class="mt-2 pa-3"><v-icon class="mr-2">
                                ri-survey-line</v-icon>
                              Lihat File</a>
                            <br />
                            <v-btn :outlined="btnRespon" x-small class="mt-2" color="primary" elevation="0" @click="
                              respon = !respon;
                            btnRespon = !btnRespon;
                            ">
                              {{ !btnRespon ? 'Tutup' : 'Beri Respon' }}
                            </v-btn>
                            <v-btn outlined x-small class="mt-2 ml-3" color="primary" elevation="0"
                              @click="showHistoriesUpload(act.upload.upload_at)">
                              Lihat History Upload
                            </v-btn>

                            <ValidationObserver ref="observerFeedback">
                              <v-form>
                                <v-card v-show="respon">
                                  <v-card-text>
                                    <v-col cols="12">
                                      <v-text-field filled hide-details="auto" type="number" class="border-0"
                                        placeholder="Masukkan Nilai" v-model="act.upload.note.score"
                                        @keypress="onlyNumber" @keyup="changeScoreNumber"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-textarea counter="250" @keyup="
                                        changeFeedbackText(
                                          index,
                                          i,
                                          act.upload.note.text
                                        )
                                        " filled hide-details="auto" class="border-0" placeholder="Masukkan Respon"
                                        v-model="act.upload.note.text"></v-textarea>
                                    </v-col>

                                    <v-alert type="error" outlined dense v-show="responseErrorFeedback">
                                      {{ responseErrorFeedback }}
                                    </v-alert>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn outlined color="primary" x-small elevation="0" @click="
                                      respon = false;
                                    btnRespon = true;
                                    ">
                                      Tutup
                                    </v-btn>
                                    <v-btn class="white--text" color="primary" x-small @click="
                                      sendFeedback(
                                        item.id,
                                        act.id,
                                        act.upload.note.score,
                                        act.upload.note.text
                                      )
                                      " :loading="submitting">
                                      Kirim Respon
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-form>
                            </ValidationObserver>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white--text" color="primary" small elevation="0" @click="cancelChange()">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="is_voucher.show" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            <span> Detail Voucher </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <!-- {{ detailVoucher.id }} -->
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Kode Voucher</td>
                      <td>{{ detailVoucher.license }}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td>{{ detailVoucher.member.fullname }}</td>
                    </tr>
                    <tr>
                      <td>Nomor Whatsapp</td>
                      <td>{{ detailVoucher.member.phone }}</td>
                    </tr>
                    <tr>
                      <td>Nama Program</td>
                      <td>{{ detailVoucher.program.title }}</td>
                    </tr>
                    <tr>
                      <td>Progress</td>
                      <td>{{ detailVoucher.results.progress }} %</td>
                    </tr>
                    <tr>
                      <td>Nilai Pre Test</td>
                      <td>{{ detailVoucher.results.scores.pre_test.score }}</td>
                    </tr>
                    <tr>
                      <td>Nilai Post Test</td>
                      <td>
                        {{ detailVoucher.results.scores.post_test.final_score }}
                      </td>
                    </tr>
                    <tr>
                      <td>Tanggal Mulai</td>
                      <td>
                        {{ currentDateTime(detailVoucher.start_test_at) }}
                      </td>
                    </tr>
                    <tr>
                      <td>Tanggal Penyelesaian</td>
                      <td v-if="
                        detailVoucher.end_test_at != null &&
                        detailVoucher.results.progress === 100
                      ">
                        {{ currentDateTime(detailVoucher.end_test_at) }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white--text" color="primary" small elevation="0" :loading="submitting"
              @click="is_voucher.show = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- MODAL LIST HISTORY UPLOAD -->
      <v-dialog v-model="dialog_history_upload" max-width="600px">
        <v-card>
          <v-card-title>
            <span> Detail History Upload </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <!-- <pre>
                {{ history_upload }}
              </pre> -->
              <v-simple-table v-if="history_upload.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Tanggal Pengumpulan</th>
                      <th>Jam Pengumpulan</th>
                      <!-- <th>Score</th> -->
                      <!-- <th>Feedback Penilaian</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in history_upload" :key="i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ formatDateHistory(item) }}</td>
                      <td>{{ formatTimeHistory(item) }}</td>
                      <!-- <td>
                        {{ item.score }}
                      </td> -->
                      <!-- <td>
                        <div v-html="item.text" />
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white--text" color="primary" small elevation="0" :loading="submitting"
              @click="dialog_history_upload = false; history_upload = []">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar top v-model="snackbar.state" color="primary" outlined :timeout="timeout">
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>

    <v-dialog v-model="showFilter" persistent :fullscreen="$vuetify.breakpoint.name == 'xs'" width="650">
      <v-card class="rounded-lg box-shadow">
        <v-card-title class="mb-4 body-2 text-first d-flex" style="background-color: #f05326">
          <span class="white--text"> Filter </span>
          <v-spacer></v-spacer>
          <v-icon @click="showFilter = false" color="white" :disabled="process.export">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <v-alert v-show="errorMessages.length > 0" type="error" class="mt-4">
            <div v-html="errorMessages" class="font-weight-medium subtitle-2" />
          </v-alert>
          <!-- <v-text-field
            outlined
            dense
            flat
            v-model="filter.pmo_voucher"
            label="Please Input License Code"
            class="mb-4"
            color="primary"
          >
          </v-text-field> -->
          <v-text-field outlined dense flat v-model="filter.license" label="Please Input Voucher Code" class="mb-4"
            color="primary">
          </v-text-field>
          <v-autocomplete outlined dense flat v-model="filter.member_id" :search-input.sync="searchMember"
            :items="memberList" hide-no-data hide-selected label="Please Select Member" class="mb-4" item-value="id"
            item-text="fullname" color="primary">
          </v-autocomplete>
          <v-select v-model="filter.progress" :items="progs" class="mb-4" label="Please Select Progress"
            :item-text="'name'" :item-value="'id'">
          </v-select>
          <v-select outlined flat v-model="filter.feedback_status" label="Please Select Feedback Status"
            class="mb-4" color="primary" item-value="value" item-text="text" :items="[
              { text: 'Selesai Dinilai', value: 'complete' },
              { text: 'Belum Selesai Dinilai', value: 'pending' },
            ]">
          </v-select>
          <v-row id="progres-icon">
            <v-col cols="12" md="5">
              <div class="mb-2">Tanggal Redeem Mulai</div>
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="start_date" prepend-inner-icon="ri-calendar-line" v-bind="attrs" v-on="on"
                    @click:clear="start_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    placeholder="Masukkan Tanggal Awal disini" clearable clear-icon="ri-close-circle-fill"></v-text-field>
                </template>
                  <!-- @input="() => {
                    start_date = formatDate(start_date)
                    menu1 = false
                  }" -->
                <v-date-picker v-model="start_date" :active-picker.sync="activePicker1"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  min="1950-01-01" @change="save1"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="5">
              <div class="mb-2">Tanggal Redeem Akhir</div>
              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="end_date" prepend-inner-icon="ri-calendar-line" v-bind="attrs" v-on="on"
                    placeholder="Masukkan Tanggal Akhir disini"
                    @click:clear="end_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    clearable clear-icon="ri-close-circle-fill"></v-text-field>
                </template>
                  <!-- @input="() => {
                    end_date = formatDate(end_date)
                    menu2 = false
                  }" -->
                <v-date-picker v-model="end_date" :active-picker.sync="activePicker2"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  min="1950-01-01" @change="save2"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="pl-0">
              <div class="mb-2 white--text">Tanggal</div>
              <v-btn color="primary" elevation="0" @click="resetTgl()" :block="$vuetify.breakpoint.name !== 'xs'">
                {{ $vuetify.breakpoint.name !== 'xs' ? 'Reset' : 'Reset Tanggal' }}
              </v-btn>
            </v-col>
          </v-row>
          <div class="mt-3 caption red--text font-italic">
            Klik icon calendar untuk mengisi tanggal secara manual menggunakan format {{ convertExample("") }} atau {{ convertExample("backslash") }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed small :loading="process.run" :disabled="process.export" class="text-capitalize mr-2" @click="closeFilterDialog('close')">
            Close
          </v-btn>
          <v-menu bottom offset-y origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="white--text" color="teal" small :disabled="process.run"
                :loading="process.export">
                <v-icon left>ri-file-excel-line</v-icon>
                Export
                <v-icon right>ri-arrow-down-s-line</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="handleExportData('attachment_epm')">
                <v-list-item-content>
                  <v-list-item-title>
                    Export Attachment TPM & UK
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handleExportData('score_epm')">
                <v-list-item-content>
                  <v-list-item-title>
                    Export Nilai TPM & UK
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn depressed small color="red" class="white--text text-capitalize" :loading="process.run" :disabled="process.export"
            @click="closeFilterDialog('reset')">
            Reset
          </v-btn>
          <v-btn depressed small color="#EF6C00" class="white--text text-capitalize" :loading="process.run" :disabled="process.export"
            @click="closeFilterDialog('filter')">
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ProgramAll from "./components/table";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "user-list",
  components: { ProgramAll },
  data: () => ({
    showFilter: false,
    filter: {},
    start_date: null,
    end_date: null,
    start_date_temp: null,
    end_date_temp: null,
    activePicker1: null,
    activePicker2: null,
    errorMessages: "",
    progress: "",
    progress_start: "",
    progress_end: "",
    menu1: false,
    menu2: false,
    newPaket: "",
    searchMember: "",
    table_loading: false,
    loading: false,
    process: {
      run: false,
      export: false
    },
    all: {},
    member: {},
    topics: [],
    memberList: [],
    progs: [
      {
        id: '1',
        name: 'All'
      },
      {
        id: '2',
        name: '100%'
      },
    ],
    idMember: "",
    tabPaket: null,
    submitting: false,
    is_change: {
      show: false,
      id: "",
    },
    snackbar: {
      state: false,
      text: "",
    },
    timeout: 5000,
    is_list: {
      show: false,
      id: "",
    },
    is_voucher: {
      show: false,
      id: "",
    },
    new_status: "",
    new_items: "",
    prog: {},
    name: "",
    menu: false,
    date: null,
    age: "",
    respon: false,
    btnRespon: true,
    dialog_history_upload: false,
    history_upload: [],
    act: {
      upload: {
        note: {
          text: "",
          score: "",
        },
      },
    },
    responseErrorFeedback: "",
    detailVoucher: {},
    url_: "",
    code: "",
    title: "",
    dir: "",
    limit: 10,
  }),
  computed: {
    isPaketEmpty: () => false,
    // ...mapGetters("user", ["list", "page", "limit", "total"]),
    ...mapGetters({ data: "user/data", params: "user/params" }),
  },
  mounted() {
    // this.$emit("page-changed", 0, { title: this.title, link:`/program/users/${this.$route.params.id}` });
    this.$emit("page-changed", 1, {
      title: "Daftar Peserta",
      link: this.$route.path,
    });
    this.fetchPeserta({ member_id: "" });
    // this.fetchDetail();
  },
  created() { },
  methods: {
    convertExample(type) {
      return moment(new Date()).format(type === "backslash" ? "YYYY/MM/DD" : "YYYY-MM-DD");
    },
    save1(start_date) {
      this.$refs.menu1.save(start_date)
      // this.start_date = this.formatDate(start_date)

    },
    save2(end_date) {
      this.$refs.menu2.save(end_date)
      // this.end_date = this.formatDate(end_date)
    },
    formatDate(date) {
      //fungsi ini digunakan ketika memilih tanggal tidak dengan cara ketik manual 

      if (!date) return null

      const [year, month, day] = date.split('-')
      // if(flag === 'start_date') this.start_date = `${day}-${month}-${year}`
      // else this.end_date = `${day}-${month}-${year}`
      return `${day}-${month}-${year}`
    },
    formatDateHistory(date) {
      return moment(date).format("DD MMMM YYYY")
    },
    formatTimeHistory(date) {
      return moment(date).format("HH:mm:ss")
    },
    resetTgl() {
      this.start_date = null
      this.end_date = null
    },
    save(date) {
      this.$refs.menu.save(date);
      var today = new Date();
      var birthDate = new Date(this.date);
      this.age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        this.age--;
      }
      return this.age;
    },
    fetchAll() {
      this.fetchPeserta();
    },
    fetchDetail() {
      this.axios
        .get(`/program/v1/company/detail/${this.$route.params.id}`)
        .then((res) => {
          this.title = res.data.data.title;
        });
    },
    closeFilterDialog(action) {
      this.showFilter = false;
      switch (action) {
        case "filter":
          if (this.filter.progress === '1') {
            this.progress_start = 0;
            this.progress_end = 100;
          } else if (this.filter.progress === '2') {
            this.progress_start = 100;
            this.progress_end = 100;
          } else {
            this.progress_start = null;
            this.progress_end = null;
          }
          this.fetchPeserta({ page: 1 });
          break;
        case "reset":
          this.$set(this.filter, 'license', '')
          this.$set(this.filter, 'pmo_voucher', '')
          this.$set(this.filter, 'member_id', '')
          this.$set(this.filter, 'feedback_status', '')
          this.$set(this.filter, 'progress', '')
          this.start_date = null
          this.end_date = null
          this.start_date_temp = null
          this.end_date_temp = null
          this.progress_start = null
          this.progress_end = null
          this.fetchPeserta({ page: 1 });
          break;

        default:
          break;
      }
    },
    async fetchMemberRedeem() {
      this.process.run = true;
      await this.axios.get(`users/v1/company/list-member`, {
        params: {
          fullname: this.searchMember,
          limit: -1,
        }
      }).then((res) => {
        this.memberList = res.data.data.list;
      }).finally(() => {
        this.process.run = false;
      });
    },

    getLastDaysInMonth(m, y) {
      if(m < 1 || m > 12) return 0;
      return m===2 ? y & 3 || !(y%25) && y & 15 ? 28 : 29 : 30 + (m+(m>>3)&1);
    },


    validateInputDate(start_date_arr, end_date_arr) {
      let isValid = true
      this.errorMessages = ""

      // console.log(start_date_arr, end_date_arr);


      // get last date in this month
      const lastDayStartInMonth = this.getLastDaysInMonth(Number(start_date_arr[1]), Number(start_date_arr[0]))
      const lastDayEndInMonth = this.getLastDaysInMonth(Number(end_date_arr[1]), Number(end_date_arr[0]))

      // console.log(lastDayStartInMonth);
      // console.log(lastDayEndInMonth);


      // pengecekan tanggal tidak boleh lebih dari tanggal akhir bulan yang di input
      if (start_date_arr[2] > lastDayStartInMonth) {
        isValid = false
        this.errorMessages += `Tanggal mulai tidak boleh lebih besar dari ${lastDayStartInMonth}. <br/>`
      }

      // pengecekan tanggal tidak boleh lebih dari tanggal akhir bulan yang di input
      if (end_date_arr[2] > lastDayEndInMonth || end_date_arr[1] > 12) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih besar dari ${lastDayEndInMonth}. <br/>`
      }

      // pengecekan bulan tidak boleh lebih dari 12
      if (start_date_arr[1] > 12 || end_date_arr[1] > 12) {
        isValid = false
        this.errorMessages += `Bulan tidak boleh lebih besar dari 12 bulan. <br/>`
      }

      // for formating input 01-12-2024 or 01/12/2024 to 2024-12-01
      // const start_date_temp = `${start_date_arr[2]}-${start_date_arr[1]}-${start_date_arr[0]}`
      // const end_date_temp = `${end_date_arr[2]}-${end_date_arr[1]}-${end_date_arr[0]}`


      // for formating input 2024-12-01 or 2024/12/01 to 2024-12-01
      const start_date_temp = `${start_date_arr[0]}-${start_date_arr[1]}-${start_date_arr[2]}`
      const end_date_temp = `${end_date_arr[0]}-${end_date_arr[1]}-${end_date_arr[2]}`

      // pengecekan tanggal inputan dari hari ini
      const date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

      // pengecekan tanggal tidak boleh lebih dari hari ini
      if (start_date_temp > date_now) {
        isValid = false
        this.errorMessages += `Tanggal mulai tidak boleh lebih besar dari hari ini. <br/>`
      }

      if (end_date_temp > date_now) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih besar dari hari ini. <br/>`
      }

      // pengecekan tanggal akhir tidak boleh lebih kecil dari tanggal mulai
      if (end_date_temp < start_date_temp) {
        isValid = false
        this.errorMessages += `Tanggal akhir tidak boleh lebih kecil dari tanggal mulai. <br/>`
      }

      // pengecekan tahun tidak boleh lebih dari tahun ini
      const year_now = new Date().getFullYear()

      if (start_date_arr[2] > year_now) {
        isValid = false
        this.errorMessages += `Tahun mulai tidak boleh lebih besar dari tahun saat ini. <br/>`
      }

      if (end_date_arr[2] > year_now) {
        isValid = false
        this.errorMessages += `Tahun akhir tidak boleh lebih besar dari tahun saat ini. <br/>`
      }


      return isValid
    },

    async fetchPeserta(params = {}) {
      let start_date_arr = null
      let end_date_arr = null

      // console.table({startDate: `${this.start_date}`, endDate: `${this.end_date}`,url, data,})

      if (this.start_date && this.end_date) {
        // jika tanggal includes -
        if (this.start_date.includes("-")) start_date_arr = this.start_date.split("-")
        if (this.end_date.includes("-")) end_date_arr = this.end_date.split("-")

        // jika tanggal includes /
        if (this.start_date.includes("/")) start_date_arr = this.start_date.split("/")
        if (this.end_date.includes("/")) end_date_arr = this.end_date.split("/")

        // for formating input 01-12-2024 or 01/12/2024 to 2024-12-01
        // this.start_date_temp = `${start_date_arr[2]}-${start_date_arr[1]}-${start_date_arr[0]}`
        // this.end_date_temp = `${end_date_arr[2]}-${end_date_arr[1]}-${end_date_arr[0]}`


        // for formating input 2024-12-01 or 2024/12/01 to 2024-12-01
        this.start_date_temp = `${start_date_arr[0]}-${start_date_arr[1]}-${start_date_arr[2]}`
        this.end_date_temp = `${end_date_arr[0]}-${end_date_arr[1]}-${end_date_arr[2]}`

        // sending to validate format is arr: [01, 12, 2024] : is formating date 01-12-2024
        // sending to validate format is arr: [2024, 12, 01] : is formating date 2024-12-01
        if (!this.validateInputDate(start_date_arr, end_date_arr)) {
          this.submitting = false;
          this.snackbar.text = 'Periksa kembali tanggal yang Anda isikan';
          this.snackbar.state = true;
          return
        }

      }

      this.table_loading = true;
      this.process.run = true;
      this.process.export = true;

      // console.log(this.progress_start, this.progress_end, this.filter.progress);

      this.$store
        .dispatch("user/list", {
          id: this.$route.params.id,
          start_date: this.start_date_temp,
          end_date: this.end_date_temp,
          progress_start: this.progress_start,
          progress_end: this.progress_end,
          feedback_status: this.filter.feedback_status,
          sort: "results.progress",
          dir: "desc",
          ...this.filter,
          ...params,
        })
        .finally(() => {
          this.showFilter = false;
          this.table_loading = false;
          this.process.run = false;
          this.process.export = false;
        });
    },
    sorting(value) {
      if (value === true) {
        this.dir = "desc";
      } else {
        this.dir = "asc";
      }
      this.table_loading = true;
      this.$store
        .dispatch("user/list", {
          id: this.$route.params.id,
          q: this.name,
          sort: "results.progress",
          dir: this.dir,
        })
        .finally(() => {
          this.table_loading = false;
        });
    },
    async detail(program) {
      this.is_list.id = program.id;
      await this.getDetail(program.id);
      this.is_list.show = true;
    },
    getDetail(id) {
      this.idMember = id;
      this.axios
        .get(`/program//v1/trainer/member-redeem/detail/${this.idMember}`)
        .then((res) => {
          this.topics = res.data.data.topics;
        });
    },

    showHistoriesUpload(histories) {
      if (!histories) {
        return this.snackbar = {
          state: true,
          text: "Tidak terdapat history upload yang tersedia"
        }
      }

      this.dialog_history_upload = true
      this.history_upload = histories

    },
    getDetailMember(id) {
      this.idMember = id;
      this.axios
        .get(`users/v1/member/detail_by_id/${this.idMember}`)
        .then((res) => {
          this.member = res.data.data;
          this.code = this.member.meta?.code || "";
        });
    },
    change(member) {
      this.is_change.show = true;
      this.is_change.id = member;
      this.getDetailMember(member);
    },
    getVoucher(id) {
      this.idMember = id;
      this.axios
        .get(`/program/v1/company/detail-member-redeem/${this.idMember}`)
        .then((res) => {
          this.detailVoucher = res.data.data;
        });
    },
    voucher(member) {
      this.is_voucher.show = true;
      this.is_voucher.id = member.id;
      this.getVoucher(member.id);
    },
    currentDateTime(a) {
      return moment(a).format("L");
    },
    editMember(id) {
      this.submitting = true;

      const data = {
        fullname: this.member.fullname,
        date_of_birth: this.member.date_of_birth,
        phone: this.member.phone,
        email: this.member.email,
        meta: { code: this.code },
      };
      this.axios
        .put(`users/v1/member/update_by_id/${id}`, data)
        .then(() => {
          this.submitting = false;
          this.is_change.show = false;
          this.fetchPeserta();
          this.snackbar.text = `Data Peserta berhasil diubah`;
          this.snackbar.state = true;
        })
        .catch((res) => {
          this.submitting = false;
          if (res.response) {
            this.snackbar.text = res.response.data.message;
            this.snackbar.state = true;
          }
        });
    },
    // update(item, status){
    //   this.new_items = item;
    //   this.new_status = status;
    // },
    cancel() {
      this.is_change.show = false;
    },
    cancelChange() {
      this.is_list.show = false;
    },
    hapus(id) {
      this.is_change.id = id;
      this.submitting = true;
      this.axios
        .delete(`/users/v1/company/program/delete/${this.is_change.id}`)
        .then((res) => {
          this.submitting = false;
          this.is_change.show = false;
          if (res.status == 200) {
            this.snackbar.text = `Program berhasil dihapus`;
            this.snackbar.state = true;
            this.fetchAll();
          } else {
            this.snackbar.text = res.message;
            this.snackbar.state = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
        $event.preventDefault();
      }
    },
    changeFeedbackText(top_idx, act_idx) {
      if (
        this.topics[top_idx].activities[act_idx].upload.note.text.length > 250
      ) {
        this.topics[top_idx].activities[act_idx].upload.note.text = this.topics[
          top_idx
        ].activities[act_idx].upload.note.text.substring(0, 250);
      }
    },
    changeScoreNumber() {
      if (parseInt(this.act.upload.note.score) > 100) {
        this.act.upload.note.score = 100;
      } else if (parseInt(this.act.upload.note.score) < 0) {
        this.act.upload.note.score = 0;
      }
    },
    async sendFeedback(topic, act, score, text) {
      this.submitting = true;
      let params = {
        member_redeem_id: this.idMember,
        topic_id: topic,
        activity_id: act,
        score: parseInt(score),
        text: text,
      };
      await this.axios
        .put(`/users/v1/trainer/feedback-mr`, params)
        .then((res) => {
          this.btnRespon = true;
          this.respon = false;
          this.submitting = false;
          if (res.status == 200) {
            this.snackbar.text = `Feedback berhasil dikirim`;
            this.snackbar.state = true;
          } else {
            this.snackbar.text = res.message;
            this.snackbar.state = true;
          }
        })
        .catch((error) => {
          this.submitting = false;
          let message = "";
          if (error instanceof Error) {
            message = error.message;
            if (error.response?.data?.message) {
              message = error.response.data.message;
            }
          }

          this.responseErrorFeedback = message;

          setTimeout(() => {
            this.responseErrorFeedback = "";
          }, 4000);

          console.error(error);
        }).finally(() => {
          this.fetchAll();
        });
    },
    handleExportData(exportState) {
      this.process.export = true;
      if (this.filter.progress === '1') {
        this.progress_start = 0;
        this.progress_end = 100;
      } else if (this.filter.progress === '2') {
        this.progress_start = 100;
        this.progress_end = 100;
      } else {
        this.progress_start = null;
        this.progress_end = null;
      }

      const data = {
        id: [this.$route.params.id],
        // name: this.name_of_export
      }

      switch (exportState) {
        case "epm":
          this.ExportDataProgram(`users/v1/export/member_redeem_epm`, data)
          break;
        case "attachment_epm":
          this.ExportDataProgram(`users/v1/export/member_redeem_attachment`, data)
          break;
        case "score_epm":
          this.ExportDataProgram(`users/v1/export/member_redeem_uk_epm`, data)
          break;
        default:
          this.ExportDataProgram(`users/v1/export/member_redeem`, data)
          break;
      }
    },

    async ExportDataProgram(url, data) {
      await this.axios
        .post(`${url}`, data, {
          params: {
            sort: this.sort,
            dir: this.dir,
            limit: -1,
            license: this.filter.license,
            pmo_code: this.filter.pmo_code,
            member_id: this.filter.member_id,
            start_date: this.start_date_temp,
            end_date: this.end_date_temp,
            progress_start: this.progress_start,
            progress_end: this.progress_end,
            feedback_status: this.filter.feedback_status,
            range_date_type: 'created_at',
          }
        })
        .then((res) => {
          const { data: resData } = res.data
          this.snackbar.text = `Data berhasil di export`;
          this.snackbar.state = true;
          this.process.export = false;
          // this.closeFilter('reset')
          // this.start_date = null;
          // this.end_date = null;
          if (resData.public_url) {
            this.forceFileDownload(resData)
          } else {
            this.snackbar.text = 'Data file export tidak tersedia';
            this.snackbar.state = true;
          }
        }).catch((error) => {
          this.process.export = false;
          this.snackbar.text = error.response.data.message;
          this.snackbar.state = true;
        }).finally(() => {
          this.closeFilterDialog('close')
        })
    },

    forceFileDownload(data) {
      const link = document.createElement('a')
      link.href = data.public_url
      link.setAttribute('download', `${data.title}.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    // menu1(val) {
    //   val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    // },
    // menu2(val) {
    //   val && setTimeout(() => (this.activePicker2 = 'YEAR'))
    // },
    searchMember(val) {
      if (val && val.length > 2) {
        this.fetchMemberRedeem();
      }
    },
  },
};
</script>